<template>
<div id="app">
    <div class="w-100 container-fluid px-2">
        <router-view></router-view>
    </div>
    <footer class="w-100 mt-5 d-flex justify-content-center align-center">
        <p class="text-disabled"> &copy; GTECH - {{ new Date().getFullYear() }}</p>
    </footer>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import store from '@/store'

export default {
    data() {
        return {
            user: null,
            menuData: [{
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
                {
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
                {
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
                {
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
                {
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
                {
                    "text": "Dashboards",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Analytics",
                            "link": "index.html",
                            "icon": "ti-chart-pie-2",
                            "active": false
                        },
                        {
                            "text": "CRM",
                            "link": "dashboards-crm.html",
                            "icon": "ti-3d-cube-sphere",
                            "active": false
                        },
                        {
                            "text": "eCommerce",
                            "link": "app-ecommerce-dashboard.html",
                            "icon": "ti-shopping-cart",
                            "active": false
                        },
                        {
                            "text": "Logistics",
                            "link": "app-logistics-dashboard.html",
                            "icon": "ti-truck",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "active": false,
                    "submenu": [{
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        },
                        {
                            "text": "Academy",
                            "link": "app-academy-dashboard.html",
                            "icon": "ti-book",
                            "active": false
                        }
                    ]
                },
                {
                    "text": "Tests",
                    "icon": "ti-smart-home",
                    "link": "app-academy-dashboard.html",
                    "active": true
                },
            ],
            meta: {}
        };
    },
    name: 'App',
    computed: {
        ...mapGetters('app', ['filteredMenuItems']),
        ...mapGetters('app', ['userInfo']),
        ...mapGetters('app', ['userEmail'])

    },
    components: {},
    watch: {
        $route(to, from) {
            // Mettre à jour meta à chaque changement de route
            this.meta = to.meta;
            this.configureDomaines()
        }
    },
    mounted() {
        const appLogo = document.getElementById('appLogo')
        let appLogoLink = `https://api.supervizr.net/logo/${this.$store.state.app.subDomain}.supervizr.png`

        appLogo.src = appLogoLink
        const appLogo1 = document.getElementById('appLogo1');
        const appLogo2 = document.getElementById('appLogo2');
        appLogo1.src = appLogoLink;
        appLogo2.src = appLogoLink;
        console.log('Voici les elements ', this.filteredMenuItems, this.menuData)
        const createMenuItem = (item) => {
            let li = `<li class="menu-item ${item.active ? 'active' : ''}">`;
            li += `<a href="${item.link || 'javascript:void(0)'}" class="menu-link ${item.submenu ? 'menu-toggle' : ''}">`;
            li += `<i class="menu-icon tf-icons ti ${item.icon}"></i>`;
            li += `<div data-i18n="${item.text}">${item.text}</div>`;
            li += `</a>`;

            if (item.submenu && item.submenu.length > 0) {
                li += '<ul class="menu-sub">';
                item.submenu.forEach(subItem => {
                    li += createMenuItem(subItem);
                });
                li += '</ul>';
            }

            li += '</li>';
            return li;
        };
       
        // Check if the menu-toggle-hamburger button already exists
        if (!document.querySelector('.menu-toggle-hamburger')) {
             // Sélectionner l'élément parent de l'élément <a> où tu veux placer le bouton
        var menuBlock = document.getElementById('menu-block1');
            // Créer le bouton de fermeture
            var closeButton = document.createElement('button');
            closeButton.classList.add('menu-close'); // Ajoute la classe
            closeButton.id = 'menuCloseButton'; // Définit l'ID
            closeButton.textContent = '✖'; // Définit le texte du bouton

            // Ajouter le bouton après l'élément <a> (avant la liste <ul>)
            menuBlock.insertBefore(closeButton, document.getElementById('menu1'));
            // Create the hamburger button dynamically
            const menuToggleButton1 = document.createElement('button');
            menuToggleButton1.classList.add('menu-toggle-hamburger'); // Add the appropriate class for styling

            menuToggleButton1.id = 'menuToggleButton'; // Assign ID for targeting

            // Create the span that will hold the hamburger icon
            const menuIcon = document.createElement('span');
            menuIcon.classList.add('menu-icon'); // Add any classes if necessary
            menuIcon.textContent = '☰'; // The hamburger icon (☰)

            menuToggleButton1.appendChild(menuIcon); // Append the icon inside the button

            // Insert the button into the DOM (inside the container)
            const menuContainer1 = document.getElementById('menuContainer1');
            menuContainer1.appendChild(menuToggleButton1);
            // Create a menu item element dynamically
        }
        const createMenuItem1 = (item) => {
            let li = `<li class="menu-item ${item.active ? 'active' : ''}">`;

            // Start the anchor tag with the submenu toggle class if there's a submenu
            li += `<a href="${item.link || 'javascript:void(0)'}" class="menu-link ${item.submenu ? 'menu-toggle' : ''}" aria-expanded="false" aria-controls="submenu-${item.id}">`;
            li += `<i class="menu-icon tf-icons ti ${item.icon}"></i>`;
            li += `<div data-i18n="${item.text}">${item.text}</div>`;

            // Add chevron icon to the right, only for items with a submenu
            if (item.submenu && item.submenu.length > 0) {
                li += `<i class="chevron-icon tf-icons ti-angle-right"></i>`;
            }

            li += `</a>`;

            // If there is a submenu, recursively create it
            if (item.submenu && item.submenu.length > 0) {
                li += '<ul class="menu-sub">';
                item.submenu.forEach(subItem => {
                    li += createMenuItem(subItem);
                });
                li += '</ul>';
            }

            li += '</li>';
            return li;
        };

        // Fonction pour basculer la visibilité du menu mobile
        function toggleMobileMenu() {
            const menuBlock = document.getElementById('menu-block1');
            const layoutMenu = document.getElementById('layout-menu1');

            // Vérification de l'état du menu et basculement
            console.log('toggleMobileMenu called');

            if (layoutMenu.classList.contains('open')) {
                console.log('Menu is open, closing it now.');
                layoutMenu.classList.remove('open'); // Fermer le menu
                menuBlock.classList.remove('menu-visible'); // Masquer le bloc de menu
            } else {
                console.log('Menu is closed, opening it now.');
                layoutMenu.classList.add('open'); // Ouvrir le menu
                menuBlock.classList.add('menu-visible'); // Afficher le bloc de menu
            }
        }

        // Ajouter un écouteur d'événements pour le bouton de basculement du menu mobile
        const menuToggleButton = document.getElementById('menuToggleButton');
        if (menuToggleButton) {
            menuToggleButton.addEventListener('click', toggleMobileMenu);
        }

        // Ajouter un écouteur d'événements pour le bouton de fermeture du menu
        const menuCloseButton = document.getElementById('menuCloseButton');
        if (menuCloseButton) {
            menuCloseButton.addEventListener('click', () => {
                const layoutMenu = document.getElementById('layout-menu1');
                // const menuBlock = document.getElementById('menu-block1');

                console.log("Menu close button clicked.");

                layoutMenu.classList.remove('open'); // Fermer le menu
                // menuBlock.classList.remove('menu-visible'); // Masquer le bloc de menu
            });
        }
        // Add click event to toggle submenu on small screens
        const addMenuToggleListener = () => {
            const menuToggles = document.querySelectorAll('.menu-toggle');
            menuToggles.forEach(toggle => {
                toggle.addEventListener('click', (e) => {
                    e.preventDefault(); // Prevent default link behavior

                    // Move the code for manipulating `toggle` inside the event handler
                    const parentLi = toggle.closest('.menu-item');
                    parentLi.classList.toggle('active'); // Toggle visibility of the submenu

                    const submenu = parentLi.querySelector('.menu-sub');
                    const link = parentLi.querySelector('a');
                    link.setAttribute('aria-expanded', submenu.style.display === 'none' ? 'false' : 'true');
                });
            });
        };

        const generateMenu1 = (jsonData) => {
            let html = '';
            jsonData.forEach(item => {
                html += createMenuItem1(item); // Add each menu item to the html string
            });
            return html;
        };

        // Generate menu items dynamically
        const menuContainer = document.getElementById('menu1');
        // Generate the menu HTML and insert it into the menu container
        menuContainer.innerHTML = generateMenu1(this.filteredMenuItems);
        // Function to generate the entire menu

        store.getters['app/baseApiUrl']
        this.user = this.$store.state.app.user ?? {}
        console.log(this.user);
        const nom = document.getElementById('nom').innerHTML = `${this.user.firstName } ${this.user.lastName}` ?? "Non connecté";
        const email = document.getElementById('email').innerHTML = `${this.user.email}`;
        const generateMenu = (jsonData) => {
            let html = '<ul class="menu-inner pb-2 pb-xl-0">';
            jsonData.forEach(item => {
                html += createMenuItem(item);
            });
            html += '</ul>';
            return html;
        };

        const menuHtml = generateMenu(this.filteredMenuItems);

        document.getElementById('menu-block').innerHTML = menuHtml;
        addMenuToggleListener(); // Add event listeners for submenu toggles
        // Fonction pour créer et insérer des éléments script
        const loadScript = (src) => {
            const script = document.createElement('script');
            script.src = src;
            script.async = false; // Pour conserver l'ordre de chargement
            document.body.appendChild(script);
        };

        // Charger les scripts nécessaires
        const scripts = [
            "/assets/vendors/libs/jquery/jquery.js",
            "/assets/vendors/libs/popper/popper.js",
            "/assets/vendors/js/bootstrap.js",
            "/assets/vendors/libs/node-waves/node-waves.js",
            "/assets/vendors/libs/perfect-scrollbar/perfect-scrollbar.js",
            "/assets/vendors/libs/hammer/hammer.js",
            "/assets/vendors/libs/i18n/i18n.js",
            "/assets/vendors/libs/typeahead-js/typeahead.js",
            "/assets/vendors/js/menu.js",
            "/assets/vendors/libs/select2/select2.js",
            "/assets/vendors/libs/plyr/plyr.js",
            "/assets/js/main.js"
        ];

        scripts.forEach(src => loadScript(src));
        let deconnectionBouton = document.getElementById("boutonDeconnection");
        deconnectionBouton.addEventListener('click', function () {
            this.setUser({
                id: '',
                firstName: '',
                lastName: '',
                email: '',
                token: '',
                permissions: [],
            })
        });
        this.configureDomaines()

    },
    beforeMount() {

    },
    methods: {
        ...mapActions('app', ['setUser']),

        configureDomaines() {
            let menus = this.filteredMenuItems

            let subDomaine = this.$store.state.app.subDomain;

            let pageTitle = "";

            if (Object.keys(this.$route).includes("meta") && Object.keys(this.$route.meta).includes("pageTitle")) {

                pageTitle = this.$route.meta.pageTitle

                subDomaine = " " + pageTitle

                console.log('  Voici les parametres de la route ==>  ', pageTitle, subDomaine)

            }
            const appName = document.getElementById('appName')

            appName.textContent = subDomaine
            // Change page title
            document.title = pageTitle
            let faviconLink = `https://api.supervizr.net/logo/${this.$store.state.app.subDomain}.supervizr.ico`
            // Change favicon based on its id
            const faviconElement = document.getElementById('faviconElement')
            if (faviconElement) {
                const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link')
                favicon.type = 'image/x-icon'
                favicon.rel = 'icon'
                favicon.href = faviconLink // Replace with your default favicon path
                document.head.appendChild(favicon)
            }
        }
    }
}
</script>

<style>

#appLogo {
    width: 100%;
    height: 45px;
}

#appLogo1 {
    width: 100%;
    height: 45px;
}

#appLogo2 {
    width: 100%;
    height: 45px;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

}

.app-brand-link {
    height: 45px;
}

.menu-vertical {
    display: none !important;
}

.modal-dialog .close {
    background-color: #fff;
    border-radius: 0.25rem;
    opacity: 1;
    padding: 0.3757rem;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(47, 43, 61, 0.1);
    transition: all 0.23s ease 0.1s;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 35px;
    height: 35px;
    border: 0px !important;
}

.modal-xl {
    width: 90% !important;
    max-width: 100% !important;
}

/* Modal close button */
.modal-dialog .close {
    background-color: #fff;
    border-radius: 0.25rem;
    opacity: 1;
    padding: 0.3757rem;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(47, 43, 61, 0.1);
    transition: all 0.23s ease 0.1s;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 35px;
    height: 35px;
    border: 0px !important;
}

.menu-inner1 .menu-toggle::after {
    margin-top: 5px;
    position: absolute;
    /* Positions the icon absolutely within the container */
    top: 0;
    right: 0;
    /* Vertically centers the icon */
    display: block;
    /* Makes the pseudo-element a block-level element */
    font-family: "tabler-icons";
    /* Specifies the font family (make sure tabler-icons is included in your project) */
    font-size: 1.375rem;
    /* Adjusts the size of the icon */
    transform: translateY(0%);
    /* Corrects the vertical alignment to perfectly center the icon */
    content: "\ea61";
    /* The Unicode content for the icon (should be a valid icon in the "tabler-icons" font) */

}

/* Close button styles */
.menu-close {
    position: absolute;
    top: 8px;
    /* Adjust based on your layout */
    right: 15px;
    /* Adjust based on your layout */
    font-size: 15px;
    /* Adjust the size of the close button */
    background-color: transparent;
    border-radius: 10%;
    border: none;
    color: #2c3e50;
    cursor: pointer;
    z-index: 1001;
    /* Ensure the close button is on top */
}

/* Close button hover effect */
.menu-close:hover {
    color: #3f944a;
    background: white;
    border: 1px solid #3f944a;
    /* Change the color on hover */
}

/* Submenu Styles */
.menu-inner1 .menu-sub {
    display: none;
    /* Initially hide the submenu */
    padding-left: 5px;
    /* Indent the submenu items */
    list-style: none;
}

.menu-inner1 .menu-item:hover>.menu-sub {
    display: block;
    /* Show submenu when hovering over a menu item */
}

.menu-inner1 .menu-item .menu-toggle {
    cursor: pointer;
    /* Show pointer cursor for items that can toggle submenus */
}

/* Rotate the icon when the menu is active */
.menu-inner1 .menu-item.active .menu-toggle::after {
    transform: rotate(90deg);
    /* Rotate the chevron when submenu is active */
}

/* Styles for submenus */
.menu-inner1 .menu-sub .menu-item .menu-link {
    padding-left: 30px;
    /* More padding for submenu items */
    font-size: 14px;
}

/* Icon Style */
.menu-inner1 .menu-link i.menu-icon {
    font-size: 20px;
    color: #333;
    transition: color 0.3s;
}

/* Style for icons on hover */
.menu-inner1 .menu-link:hover i.menu-icon {
    color: #fff;
    /* Change icon color on hover */
}

/* Toggle State for Submenu */
.menu-inner1 .menu-toggle[aria-expanded="true"] .menu-icon {

    /* Rotate the icon when submenu is expanded */
}

/* Active state of the menu */
/* #layout-menu1.open {
    left: 0;
} */

/* For smaller screens, adjust button position */
@media (max-width: 768px) {
    .menu-toggle-hamburger {
        top: 10px;
        left: 10px;
    }

    .menu-close {
        font-size: 18px;
        /* Increase size for mobile */
        background: transparent
    }

    .menu-inner1 .menu-link {
        font-size: 14px;
        /* Smaller text for mobile devices */
        padding: 8px 12px;
    }

    .menu-inner1 .menu-sub {
        padding-left: 5px;
    }

    .menu-inner1 .menu-item {
        width: 100%;
    }
}

.menu-inner1 .menu-item .menu-link {
    padding: 10px;
    color: grey !important;
    margin: 5px auto;
}

.menu-inner1 .menu-item .menu-link:hover,
.menu-inner1 .menu-item.active .menu-link {
    color: white !important;
    background: #3f944a;
    border-radius: 5px;

}
.menu-inner1 .menu-item.active .menu-link i{
    color: white !important;
}

/* Responsive Styles */
@media (max-width: 767px) {
    .menu-link {
        font-size: 14px;
        /* Smaller text for mobile devices */
        padding: 8px 12px;
    }

    .menu-sub {
        padding-left: 15px;
    }

    .menu-item {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .menu-link {
        font-size: 12px;
        /* Even smaller font size for very small screens */
    }
}

/* Styles de base pour le menu */
#layout-menu1 {
    position: fixed;
    top: 0;
    left: -250px;
    /* Menu caché initialement */
    width: 250px !important;
    height: 100vh;
    background-color: white;
    transition: left 0.3s ease;
    /* Transition pour le menu horizontal */
    z-index: 1000;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
}

/* Quand la classe 'open' est ajoutée, on fait apparaître le menu */
#layout-menu1.open {
    left: 0;
}

/* Styles pour le bloc de menu */
#menu-block1 {
    visibility: hidden;
    /* Cacher initialement */
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0s 0.3s;
    /* Transition de visibilité */
}

/* Quand le bloc de menu devient visible */
#menu-block1.menu-visible {
    visibility: visible;
    /* Rendre visible le bloc */
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0s 0s;
}

/* Gestion des icônes et du bouton de menu */
.menu-toggle-hamburger {
    background: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;

}

/* Si nécessaire, ajoutez une règle pour cacher le bouton hamburger quand le menu est ouvert */
#layout-menu1.open+.menu-toggle-hamburger {
    display: none;
    /* Par exemple, cacher le hamburger quand le menu est ouvert */
}
.text-disabled{
    color:gray;
}
/* Hide #menu-block when the screen width is less than or equal to 1159px */
@media (max-width: 1190px) {
    #menu-block {
        display: none;
    }

    #menuContainer1 {
        display: block !important;
        /* Force visibility */
    }
}
</style>
