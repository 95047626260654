import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Homes/HomesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Logins/LoginsView.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/abscences',
            name: 'abscences',
            component: () => import('@/views/Abscences/AbscencesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Abscences',
                breadcrumb: [
                    {
                        text: 'Abscences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/agentsrapports',
            name: 'agentsrapports',
            component: () => import('@/views/Agentsrapports/AgentsrapportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Agentsrapports',
                breadcrumb: [
                    {
                        text: 'Agentsrapports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/agents',
            name: 'agents',
            component: () => import('@/views/Agents/AgentsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Agents',
                breadcrumb: [
                    {
                        text: 'Agents',
                        active: true,
                    },
                ],
                filter: [{
                    type: "valider",
                }
                ],
            },
        },
        {
            path: '/attributions',
            name: 'attributions',
            component: () => import('@/views/Attributions/AttributionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Attributions',
                breadcrumb: [
                    {
                        text: 'Attributions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/badges',
            name: 'badges',
            component: () => import('@/views/Badges/BadgesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Badges',
                breadcrumb: [
                    {
                        text: 'Badges',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/balises',
            name: 'balises',
            component: () => import('@/views/Balises/BalisesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Balises',
                breadcrumb: [
                    {
                        text: 'Balises',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/achatforfait',
            name: 'achatforfait',
            component: () => import('@/views/Achatsforfaits/AchatsforfaitsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Achat Forfait',
                breadcrumb: [
                    {
                        text: 'Achat Forfait',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cartes',
            name: 'cartes',
            component: () => import('@/views/Cartes/CartesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Cartes MiFare',
                breadcrumb: [
                    {
                        text: 'Cartes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/categories',
            name: 'categories',
            component: () => import('@/views/Categories/CategoriesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Categories',
                breadcrumb: [
                    {
                        text: 'Categories',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/clients',
            name: 'clients',
            component: () => import('@/views/Clients/ClientsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Clients',
                breadcrumb: [
                    {
                        text: 'Clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/configurations',
            name: 'configurations',
            component: () => import('@/views/Configurations/ConfigurationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Configurations',
                breadcrumb: [
                    {
                        text: 'Configurations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/conges',
            name: 'conges',
            component: () => import('@/views/Conges/CongesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Conges',
                breadcrumb: [
                    {
                        text: 'Conges',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/contrats',
            name: 'contrats',
            component: () => import('@/views/Contrats/ContratsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Contrats',
                breadcrumb: [
                    {
                        text: 'Contrats',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/contratsclients',
            name: 'contratsclients',
            component: () => import('@/views/Contratsclients/ContratsclientsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Contratsclients',
                breadcrumb: [
                    {
                        text: 'Contratsclients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/controlleursacces',
            name: 'controlleursacces',
            component: () => import('@/views/Controlleursacces/ControlleursaccesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: "Controlleur d'acces",
                breadcrumb: [
                    {
                        text: 'Controlleursacces',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/credits',
            name: 'credits',
            component: () => import('@/views/Credits/CreditsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Credits',
                breadcrumb: [
                    {
                        text: 'Credits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/cruds',
            name: 'cruds',
            component: () => import('@/views/Cruds/CrudsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Cruds',
                breadcrumb: [
                    {
                        text: 'Cruds',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/debits',
            name: 'debits',
            component: () => import('@/views/Debits/DebitsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Debits',
                breadcrumb: [
                    {
                        text: 'Debits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deplacements',
            name: 'deplacements',
            component: () => import('@/views/Deplacements/DeplacementsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Deplacements',
                breadcrumb: [
                    {
                        text: 'Deplacements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/deploiementspointeusesmoyenstransports',
            name: 'deploiementspointeusesmoyenstransports',
            component: () => import('@/views/Deploiementspointeusesmoyenstransports/DeploiementspointeusesmoyenstransportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Deploiementspointeusesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Deploiementspointeusesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/details',
            name: 'details',
            component: () => import('@/views/Details/DetailsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Details',
                breadcrumb: [
                    {
                        text: 'Details',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/directions',
            name: 'directions',
            component: () => import('@/views/Directions/DirectionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Directions',
                breadcrumb: [
                    {
                        text: 'Directions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/doublonsPostes',
            name: 'doublonsPostes',
            component: () => import('@/views/DoublonsPostes/DoublonsPostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'DoublonsPostes',
                breadcrumb: [
                    {
                        text: 'DoublonsPostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/echelons',
            name: 'echelons',
            component: () => import('@/views/Echelons/EchelonsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Echelons',
                breadcrumb: [
                    {
                        text: 'Echelons',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/entreprises',
            name: 'entreprises',
            component: () => import('@/views/Entreprises/EntreprisesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Entreprises',
                breadcrumb: [
                    {
                        text: 'Entreprises',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/extrasdatas',
            name: 'extrasdatas',
            component: () => import('@/views/Extrasdatas/ExtrasdatasView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Extrasdatas',
                breadcrumb: [
                    {
                        text: 'Extrasdatas',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/forfaits',
            name: 'forfaits',
            component: () => import('@/views/Forfaits/ForfaitsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Forfaits',
                breadcrumb: [
                    {
                        text: 'Forfaits',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/files',
            name: 'files',
            component: () => import('@/views/Files/FilesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Files',
                breadcrumb: [
                    {
                        text: 'Files',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/fonctions',
            name: 'fonctions',
            component: () => import('@/views/Fonctions/FonctionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Fonctions',
                breadcrumb: [
                    {
                        text: 'Fonctions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/graphiques',
            name: 'graphiques',
            component: () => import('@/views/Graphiques/GraphiquesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Graphiques',
                breadcrumb: [
                    {
                        text: 'Graphiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/historiques',
            name: 'historiques',
            component: () => import('@/views/Historiques/HistoriquesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Historiques',
                breadcrumb: [
                    {
                        text: 'Historiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/historiquecartes',
            name: 'historiquecartes',
            component: () => import('@/views/Historiquecartes/HistoriquecartesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Historique de cartes',
                breadcrumb: [
                    {
                        text: 'Historiquecartes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/homes',
            name: 'homes',
            component: () => import('@/views/Homes/HomesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Homes',
                breadcrumb: [
                    {
                        text: 'Homes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/homezones',
            name: 'homezones',
            component: () => import('@/views/Homezones/HomezonesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Homezones',
                breadcrumb: [
                    {
                        text: 'Homezones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horaireagents',
            name: 'horaireagents',
            component: () => import('@/views/Horaireagents/HoraireagentsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horaireagents',
                breadcrumb: [
                    {
                        text: 'Horaireagents',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horaires',
            name: 'horaires',
            component: () => import('@/views/Horaires/HorairesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horaires',
                breadcrumb: [
                    {
                        text: 'Horaires',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horairestypespostes',
            name: 'horairestypespostes',
            component: () => import('@/views/Horairestypespostes/HorairestypespostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horairestypespostes',
                breadcrumb: [
                    {
                        text: 'Horairestypespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/horairestypessites',
            name: 'horairestypessites',
            component: () => import('@/views/Horairestypessites/HorairestypessitesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Horairestypessites',
                breadcrumb: [
                    {
                        text: 'Horairestypessites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/identifications',
            name: 'identifications',
            component: () => import('@/views/Identifications/IdentificationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Identifications',
                breadcrumb: [
                    {
                        text: 'Identifications',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/imports',
            name: 'imports',
            component: () => import('@/views/Imports/ImportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Imports',
                breadcrumb: [
                    {
                        text: 'Imports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/joursferies',
            name: 'joursferies',
            component: () => import('@/views/Joursferies/JoursferiesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Joursferies',
                breadcrumb: [
                    {
                        text: 'Joursferies',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/journals',
            name: 'journals',
            component: () => import('@/views/Journals/JournalsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Journal pointages ',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Journals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/journalstransactions',
            name: 'journalstransactions',
            component: () => import('@/views/JournalsTransactions/JournalsTransactionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Journal transactions',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Journal transaction',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/lignes',
            name: 'lignes',
            component: () => import('@/views/Lignes/LignesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Lignes',
                breadcrumb: [
                    {
                        text: 'Lignes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/lignesmoyenstransports',
            name: 'lignesmoyenstransports',
            component: () => import('@/views/Lignesmoyenstransports/LignesmoyenstransportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Lignesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Lignesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/listings',
            name: 'listings',
            component: () => import('@/views/Listings/ListingsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Tuto Video',
                breadcrumb: [
                    {
                        text: 'Listings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/logins',
            name: 'logins',
            component: () => import('@/views/Logins/LoginsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logins',
                breadcrumb: [
                    {
                        text: 'Logins',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/logs',
            name: 'logs',
            component: () => import('@/views/Logs/LogsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logs',
                breadcrumb: [
                    {
                        text: 'Logs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maps',
            name: 'maps',
            component: () => import('@/views/Maps/MapsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Maps',
                breadcrumb: [
                    {
                        text: 'Maps',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/maintenances',
            name: 'maintenances',
            component: () => import('@/views/Maintenances/MaintenancesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Maintenances',
                breadcrumb: [
                    {
                        text: 'Maintenances',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/matrimoniales',
            name: 'matrimoniales',
            component: () => import('@/views/Matrimoniales/MatrimonialesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Matrimoniales',
                breadcrumb: [
                    {
                        text: 'Matrimoniales',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/menus',
            name: 'menus',
            component: () => import('@/views/Menus/MenusView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Menus',
                breadcrumb: [
                    {
                        text: 'Menus',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/model_has_permissions',
            name: 'model_has_permissions',
            component: () => import('@/views/Model_has_permissions/Model_has_permissionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Model_has_permissions',
                breadcrumb: [
                    {
                        text: 'Model_has_permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/model_has_roles',
            name: 'model_has_roles',
            component: () => import('@/views/Model_has_roles/Model_has_rolesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Model_has_roles',
                breadcrumb: [
                    {
                        text: 'Model_has_roles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/modelslistings',
            name: 'modelslistings',
            component: () => import('@/views/Modelslistings/ModelslistingsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Planifications',
                isProgrammations: true,
                breadcrumb: [
                    {
                        text: 'Modelslistings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/moyenstransports',
            name: 'moyenstransports',
            component: () => import('@/views/Moyenstransports/MoyenstransportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Moyenstransports',
                breadcrumb: [
                    {
                        text: 'Moyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/nationalites',
            name: 'nationalites',
            component: () => import('@/views/Nationalites/NationalitesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Nationalites',
                breadcrumb: [
                    {
                        text: 'Nationalites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_access_tokens',
            name: 'oauth_access_tokens',
            component: () => import('@/views/Oauth_access_tokens/Oauth_access_tokensView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_access_tokens',
                breadcrumb: [
                    {
                        text: 'Oauth_access_tokens',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_auth_codes',
            name: 'oauth_auth_codes',
            component: () => import('@/views/Oauth_auth_codes/Oauth_auth_codesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_auth_codes',
                breadcrumb: [
                    {
                        text: 'Oauth_auth_codes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_clients',
            name: 'oauth_clients',
            component: () => import('@/views/Oauth_clients/Oauth_clientsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_clients',
                breadcrumb: [
                    {
                        text: 'Oauth_clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_personal_access_clients',
            name: 'oauth_personal_access_clients',
            component: () => import('@/views/Oauth_personal_access_clients/Oauth_personal_access_clientsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_personal_access_clients',
                breadcrumb: [
                    {
                        text: 'Oauth_personal_access_clients',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/oauth_refresh_tokens',
            name: 'oauth_refresh_tokens',
            component: () => import('@/views/Oauth_refresh_tokens/Oauth_refresh_tokensView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Oauth_refresh_tokens',
                breadcrumb: [
                    {
                        text: 'Oauth_refresh_tokens',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/passagesrondes',
            name: 'passagesrondes',
            component: () => import('@/views/Passagesrondes/PassagesrondesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Passagesrondes',
                breadcrumb: [
                    {
                        text: 'Passagesrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pastilles',
            name: 'pastilles',
            component: () => import('@/views/Pastilles/PastillesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pastilles',
                breadcrumb: [
                    {
                        text: 'Pastilles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/permissions',
            name: 'permissions',
            component: () => import('@/views/Permissions/PermissionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Permissions',
                breadcrumb: [
                    {
                        text: 'Permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/permissionsdetails',
            name: 'permissionsdetails',
            component: () => import('@/views/Permissionsdetails/PermissionsdetailsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Permissionsdetails',
                breadcrumb: [
                    {
                        text: 'Permissionsdetails',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/perms',
            name: 'perms',
            component: () => import('@/views/Perms/PermsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Perms',
                breadcrumb: [
                    {
                        text: 'Perms',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointages',
            name: 'pointages',
            component: () => import('@/views/Pointages/PointagesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointages',
                breadcrumb: [
                    {
                        text: 'Pointages',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointeuses',
            name: 'pointeuses',
            component: () => import('@/views/Pointeuses/PointeusesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointeuses',
                breadcrumb: [
                    {
                        text: 'Pointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/terminaux',
            name: 'terminaux',
            component: () => import('@/views/Terminaux/TerminauxView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Terminaux',
                breadcrumb: [
                    {
                        text: 'Terminaux',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/pointeusestransactions',
            name: 'pointeusestransactions',
            component: () => import('@/views/Pointeusestransactions/PointeusestransactionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Pointeusestransactions',
                breadcrumb: [
                    {
                        text: 'Pointeusestransactions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/points',
            name: 'points',
            component: () => import('@/views/Points/PointsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Points',
                breadcrumb: [
                    {
                        text: 'Points',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/positions',
            name: 'positions',
            component: () => import('@/views/Positions/PositionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Positions',
                breadcrumb: [
                    {
                        text: 'Positions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postes',
            name: 'postes',
            component: () => import('@/views/Postes/PostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postes',
                hideCreate: true,
                breadcrumb: [
                    {
                        text: 'Postes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesagents',
            name: 'postesagents',
            component: () => import('@/views/Postesagents/PostesagentsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesagents',
                breadcrumb: [
                    {
                        text: 'Postesagents',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesarticles',
            name: 'postesarticles',
            component: () => import('@/views/Postesarticles/PostesarticlesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesarticles',
                breadcrumb: [
                    {
                        text: 'Postesarticles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postesglobals',
            name: 'postesglobals',
            component: () => import('@/views/Postesglobals/PostesglobalsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postesglobals',
                breadcrumb: [
                    {
                        text: 'Postesglobals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/postespointeuses',
            name: 'postespointeuses',
            component: () => import('@/views/Postespointeuses/PostespointeusesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Postespointeuses',
                breadcrumb: [
                    {
                        text: 'Postespointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/presences',
            name: 'presences',
            component: () => import('@/views/Presences/PresencesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Presences',
                breadcrumb: [
                    {
                        text: 'Presences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/prestations',
            name: 'prestations',
            component: () => import('@/views/Prestations/PrestationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Prestations',
                breadcrumb: [
                    {
                        text: 'Prestations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/preuves',
            name: 'preuves',
            component: () => import('@/views/Preuves/PreuvesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Preuves',
                breadcrumb: [
                    {
                        text: 'Preuves',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/processus',
            name: 'processus',
            component: () => import('@/views/Processus/ProcessusView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Processus',
                breadcrumb: [
                    {
                        text: 'Processus',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmations',
            name: 'programmations',
            component: () => import('@/views/Programmations/ProgrammationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Aperçue listings',
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
                isListings: true,
                validation: true,
            },
        },
        {
            path: '/programmations_LISTINGS_Valider1',
            name: 'programmations_LISTINGS_Valider1',
            component: () => import('@/views/Programmations/ProgrammationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Listings valider 1',
                validation1: true,
                isListings: true,
                isListingvalidations: true,
                filter: [{
                    type: 'listings',
                },
                ],
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
            },
        },
        
        {
            path: '/programmations_LISTINGS_Valider2',
            name: 'programmations_LISTINGS_Valider2',
            component: () => import('@/views/Programmations/ProgrammationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Listings valider 2',
                validation2: true,
                isListings: true,
                isListingvalidations: true,
                filter: [{
                    type: 'listings',
                },
                ],
                breadcrumb: [
                    {
                        text: 'Programmations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmationsdetails',
            name: 'programmationsdetails',
            component: () => import('@/views/Programmationsdetails/ProgrammationsdetailsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmationsdetails',
                breadcrumb: [
                    {
                        text: 'Programmationsdetails',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmationsrondes',
            name: 'programmationsrondes',
            component: () => import('@/views/Programmationsrondes/ProgrammationsrondesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmationsrondes',
                breadcrumb: [
                    {
                        text: 'Programmationsrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmes',
            name: 'programmes',
            component: () => import('@/views/Programmes/ProgrammesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmes',
                breadcrumb: [
                    {
                        text: 'Programmes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/programmesrondes',
            name: 'programmesrondes',
            component: () => import('@/views/Programmesrondes/ProgrammesrondesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Programmesrondes',
                breadcrumb: [
                    {
                        text: 'Programmesrondes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/provinces',
            name: 'provinces',
            component: () => import('@/views/Provinces/ProvincesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Provinces',
                breadcrumb: [
                    {
                        text: 'Provinces',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/rapportpostes',
            name: 'rapportpostes',
            component: () => import('@/views/Rapportpostes/RapportpostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapportpostes',
                breadcrumb: [
                    {
                        text: 'Rapportpostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/rapports',
            name: 'rapports',
            component: () => import('@/views/Rapports/RapportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapports Pointages ',
                breadcrumb: [
                    {
                        text: 'Rapports',
                        active: true,
                    },
                ],
                filter: [
                    {
                        type: "valider",
                    },
                ],
                statsType: 'Pointages'
            },
        },
        {
            path: '/rapportsvacations',
            name: 'rapportsvacations',
            component: () => import('@/views/RapportsVacations/RapportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Rapports Vacations ',
                breadcrumb: [
                    {
                        text: 'Rapports',
                        active: true,
                    },
                ],
                filter: [
                    {
                        type: "valider",
                    },
                ],
                statsType: 'Pointages'
            },
        },
        {
            path: '/role_has_permission',
            name: 'role_has_permission',
            component: () => import('@/views/Role_has_permission/Role_has_permissionView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Role_has_permission',
                breadcrumb: [
                    {
                        text: 'Role_has_permission',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/role_has_permissions',
            name: 'role_has_permissions',
            component: () => import('@/views/Role_has_permissions/Role_has_permissionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Role_has_permissions',
                breadcrumb: [
                    {
                        text: 'Role_has_permissions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('@/views/Roles/RolesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Roles / Profils',
                breadcrumb: [
                    {
                        text: 'Roles',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/reservations',
            name: 'reservations',
            component: () => import('@/views/Reservations/ReadReservations/ReadReservations.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Reservations / Profils',
                breadcrumb: [
                    {
                        text: 'Reservations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/services',
            name: 'services',
            component: () => import('@/views/Services/ServicesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Services',
                breadcrumb: [
                    {
                        text: 'Services',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sexes',
            name: 'sexes',
            component: () => import('@/views/Sexes/SexesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sexes',
                breadcrumb: [
                    {
                        text: 'Sexes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sites',
            name: 'sites',
            component: () => import('@/views/Sites/SitesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sites',
                breadcrumb: [
                    {
                        text: 'Sites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sitespointeuses',
            name: 'sitespointeuses',
            component: () => import('@/views/Sitespointeuses/SitespointeusesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sitespointeuses',
                breadcrumb: [
                    {
                        text: 'Sitespointeuses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/sitessdeplacements',
            name: 'sitessdeplacements',
            component: () => import('@/views/Sitessdeplacements/SitessdeplacementsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Sitessdeplacements',
                breadcrumb: [
                    {
                        text: 'Sitessdeplacements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/situations',
            name: 'situations',
            component: () => import('@/views/Situations/SituationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Situations',
                breadcrumb: [
                    {
                        text: 'Situations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/soldables',
            name: 'soldables',
            component: () => import('@/views/Soldables/SoldablesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Soldables',
                breadcrumb: [
                    {
                        text: 'Soldables',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/statszones',
            name: 'statszones',
            component: () => import('@/views/Statszones/StatszonesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Statszones',
                breadcrumb: [
                    {
                        text: 'Statszones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/Supportclientreponses',
            name: 'supportclientreponses',
            component: () => import('@/views/Supportclientreponses/SupportclientreponsesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Supportclientreponses',
                breadcrumb: [
                    {
                        text: 'Supportclientreponses',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/Supportclientrequettes',
            name: 'supportclientrequettes',
            component: () => import('@/views/Supportclientrequettes/SupportclientrequettesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Supportclientrequettes',
                breadcrumb: [
                    {
                        text: 'Supportclientrequettes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/surveillances',
            name: 'surveillances',
            component: () => import('@/views/Surveillances/SurveillancesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Logs',
                breadcrumb: [
                    {
                        text: 'Surveillances',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/switchsusers',
            name: 'switchsusers',
            component: () => import('@/views/Switchsusers/SwitchsusersView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Switchsusers',
                breadcrumb: [
                    {
                        text: 'Switchsusers',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/terminals',
            name: 'terminals',
            component: () => import('@/views/Terminals/TerminalsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Terminals',
                breadcrumb: [
                    {
                        text: 'Terminals',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/trajets',
            name: 'trajets',
            component: () => import('@/views/Trajets/TrajetsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Trajets',
                breadcrumb: [
                    {
                        text: 'Trajets',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/voyages',
            name: 'voyages',
            component: () => import('@/views/Voyages/VoyagesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Voyages',
                breadcrumb: [
                    {
                        text: 'Voyages',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/trackings',
            name: 'trackings',
            component: () => import('@/views/Trackings/TrackingsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Trackings',
                breadcrumb: [
                    {
                        text: 'Trackings',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transactionhistoriques',
            name: 'transactionhistoriques',
            component: () => import('@/views/Transactionhistoriques/TransactionhistoriquesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transactionhistoriques',
                breadcrumb: [
                    {
                        text: 'Transactionhistoriques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transactions',
            name: 'transactions',
            component: () => import('@/views/Transactions/TransactionsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transactions',
                breadcrumb: [
                    {
                        text: 'Transactions',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transporteurs',
            name: 'transporteurs',
            component: () => import('@/views/Transporteurs/TransporteursView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transporteurs',
                breadcrumb: [
                    {
                        text: 'Transporteurs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/transporteurstrajets',
            name: 'transporteurstrajets',
            component: () => import('@/views/Transporteurstrajets/TransporteurstrajetsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Transporteurstrajets',
                breadcrumb: [
                    {
                        text: 'Transporteurstrajets',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/travailleurs',
            name: 'travailleurs',
            component: () => import('@/views/Travailleurs/TravailleursView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Travailleurs',
                breadcrumb: [
                    {
                        text: 'Travailleurs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/types',
            name: 'types',
            component: () => import('@/views/Types/TypesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Types',
                breadcrumb: [
                    {
                        text: 'Types',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesabscences',
            name: 'typesabscences',
            component: () => import('@/views/Typesabscences/TypesabscencesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesabscences',
                breadcrumb: [
                    {
                        text: 'Typesabscences',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesagentshoraires',
            name: 'typesagentshoraires',
            component: () => import('@/views/Typesagentshoraires/TypesagentshorairesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesagentshoraires',
                breadcrumb: [
                    {
                        text: 'Typesagentshoraires',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typeseffectifs',
            name: 'typeseffectifs',
            component: () => import('@/views/Typeseffectifs/TypeseffectifsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typeseffectifs',
                breadcrumb: [
                    {
                        text: 'Typeseffectifs',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesheures',
            name: 'typesheures',
            component: () => import('@/views/Typesheures/TypesheuresView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesheures',
                breadcrumb: [
                    {
                        text: 'Typesheures',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesmoyenstransports',
            name: 'typesmoyenstransports',
            component: () => import('@/views/Typesmoyenstransports/TypesmoyenstransportsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesmoyenstransports',
                breadcrumb: [
                    {
                        text: 'Typesmoyenstransports',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typespointages',
            name: 'typespointages',
            component: () => import('@/views/Typespointages/TypespointagesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typespointages',
                breadcrumb: [
                    {
                        text: 'Typespointages',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typespostes',
            name: 'typespostes',
            component: () => import('@/views/Typespostes/TypespostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typespostes',
                breadcrumb: [
                    {
                        text: 'Typespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typessites',
            name: 'typessites',
            component: () => import('@/views/Typessites/TypessitesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typessites',
                breadcrumb: [
                    {
                        text: 'Typessites',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typestaches',
            name: 'typestaches',
            component: () => import('@/views/Typestaches/TypestachesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typestaches',
                breadcrumb: [
                    {
                        text: 'Typestaches',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesventilations',
            name: 'typesventilations',
            component: () => import('@/views/Typesventilations/TypesventilationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Typesventilations',
                breadcrumb: [
                    {
                        text: 'Typesventilations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/typesequipements',
            name: 'typesequipements',
            component: () => import('@/views/Typespointeuses/TypespointeusesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Types equipements',
                breadcrumb: [
                    {
                        text: 'Types equipements',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/usagers',
            name: 'usagers',
            component: () => import('@/views/Usagers/UsagersView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les usagers',
                breadcrumb: [
                    {
                        text: 'Usagers',
                        active: true,
                    },
                ],
                type: 'USAGERS',
                filter: [{
                    type: "usagers",
                },],
            },
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/Users/UsersView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les users',
                breadcrumb: [
                    {
                        text: 'Users',
                        active: true,
                    },
                ],
                type: 'USERS',
                filter: [{
                    type: "users",
                },],
            },
        },
        {
            path: '/users_SU',
            name: 'users_SU',
            component: () => import('@/views/Users/UsersView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Les super admin',
                breadcrumb: [
                    {
                        text: 'Users',
                        active: true,
                    },
                ],
                type: 'SU',
                filter: [{
                    type: "su",
                },],
            },
        },
        {
            path: '/usersgraphiques',
            name: 'usersgraphiques',
            component: () => import('@/views/Usersgraphiques/UsersgraphiquesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Usersgraphiques',
                breadcrumb: [
                    {
                        text: 'Usersgraphiques',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/userstypespostes',
            name: 'userstypespostes',
            component: () => import('@/views/Userstypespostes/UserstypespostesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Userstypespostes',
                breadcrumb: [
                    {
                        text: 'Userstypespostes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/userszones',
            name: 'userszones',
            component: () => import('@/views/Userszones/UserszonesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Userszones',
                breadcrumb: [
                    {
                        text: 'Userszones',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vehicules',
            name: 'vehicules',
            component: () => import('@/views/Vehicules/VehiculesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Vehicules',
                breadcrumb: [
                    {
                        text: 'Vehicules',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/vacations',
            name: 'vacations',
            component: () => import('@/views/Vacations/VacationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Vacations',
                breadcrumb: [
                    {
                        text: 'Vacations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/villes',
            name: 'villes',
            component: () => import('@/views/Villes/VillesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Villes',
                breadcrumb: [
                    {
                        text: 'Villes',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/ventilations',
            name: 'ventilations',
            component: () => import('@/views/Ventilations/VentilationsView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Traitements des heures travaillées',
                breadcrumb: [
                    {
                        text: 'Ventilations',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/works',
            name: 'works',
            component: () => import('@/views/Works/WorksView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Works',
                breadcrumb: [
                    {
                        text: 'Works',
                        active: true,
                    },
                ],
            },
        },
        {
            
            path: '/zones',
            name: 'zones',
            component: () => import('@/views/Zones/ZonesView.vue'),
            meta: {
                requiresAuth: true,
                pageTitle: 'Zones',
                breadcrumb: [
                    {
                        text: 'Zones',
                        active: true,
                    },
                ],
            },
        },

    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

router.beforeEach((to, from, next) => {
    console.log('  Voici les parametres de la route ==>  ',to,from,store.getters['app/menuItems'])
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // This route requires auth, check if logged in
        // If not, redirect to public page.
        const token = store.getters['app/userToken']
        if (!token) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next() // Make sure to always call next()!
    }
})
export default router
