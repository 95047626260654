import Vue from 'vue';
import App from './App.vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.config.productionTip = false;
import 'ag-grid-enterprise';

// 2. Gérer la licence immédiatement après l'import
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("For_Production-Valid_Until-22_May_2024_[v29]_MTYyMTYzODAwMDAwMA==edd463c08734ce415741e4c2b8ade1f5");

import { ModalPlugin, BOverlay, BTab, BTabs, BFormFile } from 'bootstrap-vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
// Enregistrement des composants
Vue.component('BOverlay', BOverlay);
Vue.component('BTab', BTab);
Vue.component('BTabs', BTabs);
Vue.component('BFormFile', BFormFile);

// Utilisation du ToastPlugin
// Utilisation de la bibliothèque Toastification
Vue.use(Toast, {
  // Options de configuration, si nécessaire
});

Vue.use(CKEditor);
Vue.use(ModalPlugin);
import '@/libs/toastification'
// Import d'axios et vue-axios
import axios from '@/libs/axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

// Importation du routeur et du store
import router from './router';
import store from './store';

import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
// Création de l'instance Vue
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

